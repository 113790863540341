import Input from 'designSystem/Input/Index';
import Button from 'designSystem/Button/Index';
import React from 'react';

const Example = () => {
  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M17.707,2.293C16.48,1.066,14.52,1.066,13.293,2.293L7,8.586V5c0-0.553-0.447-1-1-1s-1,0.447-1,1v10c0,0.553,0.447,1,1,1s1-0.447,1-1v-3.586l6.293,6.293C14.32,17.902,14.66,18,15,18s0.68-0.098,0.707-0.293l2-2C17.902,15.32,18,14.98,18,14.586v-10C18,3.02,17.53,2.55,17.707,2.293z"
      />
    </svg>
  );

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <Button label="Click me" svg={icon} gradient="from-green-400 to-blue-500" className="mr-4" />
      <Button label="Disabled button" disabled={false} />
      <Input
      id="input"
        label="Email address"
        type="email"
        validation="\S+@\S+\.\S+"
        onChange={undefined}
      />
    </div>
  );
};

export default Example;
