import styled from 'styled-components';
import tw from 'twin.macro';
import { SectionHeading, Subheading as SubheadingBase } from './misc/Headings';

import { ReactComponent as SvgDecoratorBlob3 } from 'assets/svg/svg-decorator-blob-3.svg';

import SupportIconImage from 'assets/svg/support-icon.svg';
import ShieldIconImage from 'assets/svg/shield-icon.svg';
import CustomizeIconImage from 'assets/svg/customize-icon.svg';
import FastIconImage from 'assets/svg/fast-icon.svg';
import ReliableIconImage from 'assets/svg/reliable-icon.svg';
import SimpleIconImage from 'assets/svg/simple-icon.svg';
import defaultCardImage from 'assets/svg/shield-icon.svg';
import { SectionDescription } from './misc/Typography';

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export const MainWrapper = ({
  cards = null,
  heading = 'Amazing Features',
  subheading = 'Features',
  description = `
  Say goodbye to time-consuming manual paperwork and embrace a more efficient hospital management system with Upslon which helps to freeing up time and resources. Our solution allows you to prioritize patient care while we handle the rest.
  Experience the difference with Upslon—try it now !`,
}: any) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: ShieldIconImage,
      title: 'Security',
      description: 'Trusted data and patient security',
    },
    {
      imageSrc: SupportIconImage,
      title: '24/7 Support',
      description: 'Always available when you need us around the clock',
    },
    {
      imageSrc: SimpleIconImage,
      title: 'Ease Of Use',
      description: 'Get your medical office in order quickly and easily',
    },

    {
      imageSrc: ReliableIconImage,
      title: 'Reliable',
      description: 'Trust in a better way to manage your practice',
    },
    { imageSrc: FastIconImage, title: 'Fast', description: 'Experience Incredible Speed' },
    {
      imageSrc: CustomizeIconImage,
      title: 'Customizable',
      description: 'Adaptable to the specifics of your demands',
    },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || 'Fully Secure'}</span>
                <p className="description">{card.description || '.'}</p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
