import tw from 'twin.macro';
import React from 'react';
import heroScreenshotImageSrc from 'assets/svg/hospitalmanament.svg';
import macHeroScreenshotImageSrc from 'assets/svg/Patientjourney.svg';
import prototypeIllustrationImageSrc from 'assets/svg/EMR.svg';
import experience from 'assets/svg/PatientExp.svg';
import billing from 'assets/svg/Billing.svg';
import appointment from 'assets/svg/Appointments.svg';
import AnimationRevealPage from 'components/hooks/AnimationRevealPage';
import { Header } from './fragments/Header';
import { MainWrapper } from './fragments/MainFeature';
import { SubMenuWrapper } from './fragments/SubMenuWrapper';
import { SubMenu } from './fragments/SubMenu';
import { Pricing } from './fragments/Pricing';
import { Faq } from './fragments/Faq';
import { SubMenuSteps } from './fragments/SubMenuSteps';
import { GetStarted } from './fragments/GetStarted';
import { Footer } from './fragments/Footer';
import './index.css';
import GlobalStyles from 'styles/GlobalStyles';
export const Homepage = () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-400`;

  return (
    <>
      <GlobalStyles />

      <AnimationRevealPage>
        <Header roundedHeaderButton={true} />
        <MainWrapper
          subheading={<Subheading></Subheading>}
          heading={
            <>
              Say Goodbye to <HighlightedText>Manual Processes</HighlightedText>
            </>
          }
        />
        <SubMenuWrapper
          subheading={<Subheading></Subheading>}
          imageSrc={heroScreenshotImageSrc}
          imageBorder={true}
          imageDecoratorBlob={true}
        />

        <SubMenu
          subheading={<Subheading></Subheading>}
          description="Simplify your scheduling with our online appointment booking."
          primaryButtonText1="View Demo"
          shouldShowButton={false}
          primaryButtonUrl="https://timerse.com"
          buttonRounded={true}
          imageRounded={true}
          imageBorder={false}
          imageShadow={false}
          iconRoundedFull={true}
          iconFilled={true}
          iconContainerCss={null}
          heading={
            <>
              Say Goodbye to<br></br> <HighlightedText>Long Waits</HighlightedText>
            </>
          }
          imageSrc={appointment}
          showDecoratorBlob={true}
          features={[]}
        />
        <SubMenu
          subheading={<Subheading></Subheading>}
          description="Smoother Patients Registration & Billing Process which results in Better Patient Experience"
          primaryButtonText1="Demo4"
          shouldShowButton={false}
          primaryButtonUrl="https://timerse.com"
          buttonRounded={true}
          imageRounded={true}
          imageBorder={false}
          imageShadow={false}
          iconRoundedFull={true}
          iconFilled={true}
          iconContainerCss={null}
          heading={
            <>
              No More <br></br> <HighlightedText>Billing Hassles</HighlightedText>
            </>
          }
          imageSrc={billing}
          showDecoratorBlob={false}
          features={[]}
          textOnLeft={false}
        />

        <SubMenu
          subheading={<Subheading></Subheading>}
          description="Manage Data and Generate Customizable EMR with also detailed reports on billing information, and much more, all at the click of a button!"
          primaryButtonText1="Demo5"
          primaryButtonUrl="https://timerse.com"
          buttonRounded={true}
          shouldShowButton={false}
          imageRounded={true}
          imageBorder={false}
          imageShadow={false}
          iconRoundedFull={true}
          iconFilled={true}
          iconContainerCss={null}
          heading={
            <>
              Power Up <br></br>
              {'      '}
              <HighlightedText>Your Organisation</HighlightedText>
            </>
          }
          imageSrc={prototypeIllustrationImageSrc}
          showDecoratorBlob={true}
          features={[]}
          textOnLeft={true}
        />

        <SubMenu
          subheading={<Subheading></Subheading>}
          primaryButtonText1="Demo6"
          shouldShowButton={false}
          primaryButtonUrl="https://timerse.com"
          buttonRounded={true}
          imageRounded={true}
          imageBorder={false}
          imageShadow={false}
          iconRoundedFull={true}
          iconFilled={true}
          iconContainerCss={null}
          heading={
            <>
              {/* Provide a Better {" "}<HighlightedText>Patient Experience</HighlightedText> */}
              Provide a Better <HighlightedText>Patient Experience</HighlightedText>
            </>
          }
          imageSrc={experience}
          showDecoratorBlob={false}
          features={[]}
          textOnLeft={false}
        />
        <SubMenuSteps
          subheading={<Subheading>STEPS</Subheading>}
          heading={
            <>
              Easy to <HighlightedText>Get Started.</HighlightedText>
            </>
          }
          textOnLeft={false}
          imageSrc={macHeroScreenshotImageSrc}
          imageDecoratorBlob={true}
          decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
        />
        <Pricing
          subheading={<Subheading>Pricing</Subheading>}
          heading={
            <>
              Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
            </>
          }
          plans={[
            {
              name: 'Starter',
              price: 'Free',
              duration: 'Forever',
              mainFeature: 'Individual',
              features: ['Appointment Booking', 'Patients Registration', '1 User'],
            },
            {
              name: 'Professional',
              price: 'INR 2499',
              duration: 'Monthly',
              mainFeature: 'For Individuals',
              features: [
                'Appointment Booking ',
                'Patients Registration',
                '+ Billing Management',
                '+ Electronic Medical Report',
                '+ 2 User',
              ],
            },
            {
              name: 'Organization',
              price: 'INR 5499',
              duration: 'Monthly',
              mainFeature: 'For Small Businesses',
              features: [
                'Appointment Booking ',
                'Patients Registration',
                'Billing Management',
                'Electronic Medical Report',
                '+ 5 User',
                ' + Communication',
              ],
              featured: true,
            },
            {
              name: 'Enterprise',
              price: 'INR 9999',
              duration: 'Monthly',
              mainFeature: 'For Large Companies',
              features: [
                'Appointment Booking ',
                'Patients Registration',
                'Billing Management',
                'Electronic Medical Report',
                ' 7 User',
                ' Communication',
                '+ Integration Support',
                '+ Customized Reports',
              ],
            },
          ]}
        />

        <Faq
          subheading={<Subheading>FAQS</Subheading>}
          heading={
            <>
              You have <HighlightedText>Questions ?</HighlightedText>
            </>
          }
          faqs={[
            {
              question: 'What are the benefits of using your hospital management software ?',
              answer:
                'Our software streamlines the administrative tasks of running a medical practice or hospital. It helps with patient scheduling, appointment management, medical record-keeping, billing, and more allowing your staff to focus on patient care instead of administrative tasks.',
            },
            {
              question: 'How does your software improve patient experience ?',
              answer:
                'Our software improves patient care by automating administrative tasks, freeing up staff to focus on patient care, improving patient communication, and ensuring that patient information is accurate and easily accessible.',
            },
            {
              question: 'How does your software handle patient scheduling?',
              answer:
                'Our software has a comprehensive patient scheduling system that allows you to schedule and manage appointments quickly and easily.',
            },
            {
              question: 'How does your software manage medical records ?',
              answer:
                'Our software has a centralized database that manages patient information and medical records, ensuring that all patient data is stored securely and can be easily accessed when needed.',
            },
            {
              question: 'How does your software handle patient communication?',
              answer:
                'Our software has a built-in patient communication system that allows you to send automated messages, appointment reminders, and other important information to patients.',
            },
            {
              question: 'Can your software help us reduce administrative costs?',
              answer:
                'Yes, our software is designed to automate administrative tasks, which can help reduce costs and improve efficiency.',
            },

            {
              question: 'How does your software ensure data security?',
              answer:
                'Our software has robust data security features, including encryption, user authentication, and access control, ensuring that patient data is protected and secure.',
            },
          ]}
        />
        <GetStarted />
        <Footer />
      </AnimationRevealPage>
    </>
  );
};
