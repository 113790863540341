import { NavLink, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useRef, ReactNode } from 'react';

import Logo from 'assets/icons/logo.svg';
import SidebarLinkGroup from './SidebarLinkGroup';
import './index.css';

interface MenuItem {
  id?: string | number;
  logo?: ReactNode;
  label: string;
  to: string;
  isDefault?: boolean;
  subMenu?: MenuItem[];
  defaultSubMenu?: string;
}

interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  menuItems: MenuItem[];
}

export const Sidebar: React.FC<SidebarProps> = (props: SidebarProps) => {
  const { sidebarOpen, setSidebarOpen, menuItems } = props;

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded: any = localStorage.getItem('sidebar-expanded');
  const [sidebarExpanded, setSidebarExpanded] = useState<any>(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true',
  );
  const [active, setActive] = useState<any>({});
  let navigate = useNavigate();

  useEffect(() => {
    const activeMenu: any = menuItems?.filter((item: any) => item?.isDefault)[0];
    setActive({
      activeId: activeMenu?.id,
      subMenuId: activeMenu?.defaultSubMenu,
    });
    navigate(activeMenu?.to);
  }, []);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (!sidebarOpen || sidebar.current.contains(target) || trigger.current.contains(target))
        return;
      setSidebarOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector('body').classList.add('sidebar-expanded');
    } else {
      document.querySelector('body').classList.remove('sidebar-expanded');
    }
  }, [sidebarExpanded]);

  return (
    <div className="bg-slate-900">
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 3xl:!w-64 shrink-0 bg-slate-800 p-4 transition-all duration-200 ease-in-out ${
          sidebarOpen ? 'translate-x-0' : '-translate-x-64'
        }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between mb-10 pr-3 sm:px-2">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-slate-500 hover:text-slate-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}
          <NavLink end to="/" className="block">
            <img src={Logo} alt="UpslonHealth" />
          </NavLink>
        </div>
        <div className="space-y-8">
          <ul>
            {menuItems?.map((item: MenuItem, index: number) => {
              return (
                <SidebarLinkGroup item={item} key={index} active={active} setActive={setActive} />
              );
            })}
          </ul>
        </div>
        {/* Expand / collapse button */}
        <div className="pt-3 hidden lg:inline-flex 3xl:hidden justify-end mt-auto">
          <div className="px-3 py-2">
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <span className="sr-only">Expand / collapse sidebar</span>
              <svg className="w-6 h-6 fill-current sidebar-expanded:rotate-180" viewBox="0 0 24 24">
                <path
                  className="text-slate-400"
                  d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
                />
                <path className="text-slate-600" d="M3 23H1V1h2z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
