import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

const SidebarLinkGroup = ({ item, active, setActive }: any) => {
  const [_subMenu, setSubMenu] = useState(false);
  const { id, isDefault = false, defaultSubMenu, logo, label, subMenu, to } = item ?? {};
  let navigate = useNavigate();

  const showSubMenu = () => {
    setSubMenu(!subMenu);
    setActive({});
  };

  return (
    <li
      className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${active.activeId == id && 'bg-slate-900'}`}
      onClick={() => {
        setActive({
          activeId: id,
          subMenuId: defaultSubMenu,
        });
      }}
    >
      <React.Fragment>
        <a
          href={to}
          className={`block text-slate-200 truncate transition duration-150`}
          onClick={(e) => {
            e.preventDefault();
            navigate(to);
            showSubMenu();
          }}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              {logo}
              <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                {label}
              </span>
            </div>
            {/* Icon */}
            <div className="flex shrink-0 ml-2">
              <svg
                className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                  _subMenu && 'rotate-180'
                }`}
                viewBox="0 0 12 12"
              >
                <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
              </svg>
            </div>
          </div>
        </a>
        <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
          <ul className={`pl-9 mt-1 ${!(active.activeId == item.id) && 'hidden'}`}>
            {subMenu?.map((subMenu: any, index: number) => {
              return (
                <li className="mb-1 last:mb-0" key={`${subMenu?.label}-${index}`}>
                  <NavLink
                    end
                    to={subMenu?.to}
                    onClick={() => {
                      setActive({
                        ...active,
                        subMenuId: subMenu.id,
                      });
                    }}
                    className={
                      'block transition duration-150 truncate ' +
                      (active.subMenuId == subMenu.id
                        ? 'text-[#692cef]'
                        : 'text-slate-400 hover:text-slate-200')
                    }
                  >
                    <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      {subMenu?.label}
                    </span>
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
      </React.Fragment>
    </li>
  );
};

export default SidebarLinkGroup;
