import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TAccount } from 'types/account';

interface AccountState {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  errorMessage: string;
  token: string;
  isLoggedIn: boolean;
  account: TAccount | null;
  isMailSent: boolean;
  isPasswordUpdated: boolean;
}

const initialState: AccountState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorMessage: '',
  account: null,
  token: '',
  isLoggedIn: false,
  isMailSent: false,
  isPasswordUpdated: false
};

export const createAccountSlice = createSlice({
  name: 'createAccount',
  initialState,
  reducers: {
    createAccountStart: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = '';
    },
    createAccountSuccess: (state, action: PayloadAction<TAccount>) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.account = action.payload;
    },
    createAccountFailure: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    fetchUserStart: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = '';
    },
    fetchUserSuccess: (state, action: PayloadAction<TAccount>) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.account = action.payload;
    },
    fetchUserFailure: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    heartbeatSessionStart: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = '';
    },
    heartbeatSessionSuccess: (state, action: PayloadAction<TAccount>) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.account = action.payload;
    },
    heartbeatSessionFailure: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    loginStart: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = '';
    },
    loginSuccess: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.token = action.payload;
      state.isLoggedIn = true;
    },
    loginFailure: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    logout: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.isLoggedIn = false;
      state.token = '';
    },
    resetPasswordStart: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = false;
      state.isMailSent = false;
      state.errorMessage = '';
    },
    resetPasswordSuccess: (state) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.isMailSent = true;
    },
    resetPasswordFailure: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.isMailSent = false;
      state.errorMessage = action.payload;
    },
  },
});

export const {
  createAccountStart,
  createAccountSuccess,
  createAccountFailure,
  fetchUserStart,
  fetchUserSuccess,
  fetchUserFailure,
  heartbeatSessionStart,
  heartbeatSessionSuccess,
  heartbeatSessionFailure,
  loginStart,
  loginSuccess,
  loginFailure,
  logout,
  resetPasswordStart,
  resetPasswordSuccess,
  resetPasswordFailure
} = createAccountSlice.actions;
