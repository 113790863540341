/* eslint-disable jsx-a11y/anchor-is-valid */
import * as Yup from 'yup';
import React, { useState } from 'react';
import Logo from 'assets/icons/logo.svg';
import { Button } from '@aurax/lumina-ui';
import Input from 'designSystem/Input/Index';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import {
  EyeIcon,
  MailIcon,
  PhoneIcon,
  EyeOffIcon,
  AdjustmentsIcon,
  OfficeBuildingIcon,
} from '@heroicons/react/outline';

import { useAppDispatch } from 'redux/store';
import { createAccount } from 'redux/thunks/accountThunk';
import RegistrationBanner from 'assets/svg/Registration.png';
import { IOrganizationSchema, IUserSchema } from 'types/account';

const Register: React.FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useAppDispatch();
  let navigate = useNavigate();

  const OrgRegisterSchema = Yup.object().shape({
    name: Yup.string().required('Organization name is required'),
    contact: Yup.string()
      .required('Contact number is required')
      .matches(
        /^\+?1?\d{9,14}$/,
        'Contact number is not valid',
      ),
    userEmail: Yup.string().email('Email must be a valid email address').required('User Email is required'),
    orgEmail: Yup.string().email('Email must be a valid email address').required('Organization Email is required'),
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
      ),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      contact: '',
      password: '',
      orgEmail: '',
      userEmail: '',
      size: undefined,
    },
    validationSchema: OrgRegisterSchema,
    onSubmit: (values: any, {resetForm, setSubmitting}) => {
      const {userEmail, orgEmail, password, name, contact, size } = values
      let userPayload: IUserSchema = {email: userEmail, password: password}
      let orgPayload:IOrganizationSchema = {name: name, email: orgEmail, phone: contact, size: size}
      dispatch(createAccount({userPayload: userPayload, orgPayload: orgPayload}));
      resetForm({
        values: {
          name: '',
          contact: '',
          password: '',
          orgEmail: '',
          userEmail: '',
          size: undefined,
        }
      });
      setSubmitting(false);
      navigate("/login");
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div className="lg:flex">
      <div className="lg:w-1/2">
        <div className="sm:py-12 lg:py-5 flex flex-col lg:flex-row items-center justify-between  bg-[#eab676] lg:bg-white lg:px-12">
          <div className="cursor-pointer mt-5 order-2 lg:order-1 lg:mr-4">
            <img src={Logo} alt="Logo" width={200} />
          </div>
          <div className="lg:mt-7 sm:mt-5 order-1 lg:order-2 lg:ml-4">
            Already have an account ?{' '}
            <a className="cursor-pointer text-[#692cef] hover:text-[#0502AB]" onClick={() => navigate("/login")}>Sign in</a>
          </div>
        </div>

        <div className="mt-10 px-12 sm:px-24 md:px-48 lg:px-12 lg:mt-16 xl:px-24 xl:max-w-2xl">
          <h2
            className="text-center text-4xl text-black font-display font-semibold xl:text-5xl
                    xl:text-bold"
          >
            Create an account
          </h2>
          <div className="mt-12">
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Input
                  id="name"
                  label="Organisation Name"
                  type="text"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  icon={<OfficeBuildingIcon />}
                  errorMessage={String(touched.name && errors.name)}
                />
                <div className="mt-4">
                  <Input
                    id="contact"
                    type="number"
                    label="Contact Number"
                    icon={<PhoneIcon />}
                    {...getFieldProps('contact')}
                    error={Boolean(touched.contact && errors.contact)}
                    errorMessage={String(touched.contact && errors.contact)}
                  />
                </div>
                <div className="mt-4">
                  <Input
                    id="size"
                    type="number"
                    mandatory={false}
                    label="Organization Size"
                    {...getFieldProps('size')}
                    icon={<AdjustmentsIcon />}
                    error={Boolean(touched.size && errors.size)}
                    errorMessage={String(touched.size && errors.size)}
                  />
                </div>
                <div className="mt-4">
                  <Input
                    id="orgEmail"
                    label="Organization Email"
                    type="email"
                    {...getFieldProps('orgEmail')}
                    icon={<MailIcon />}
                    error={Boolean(touched.orgEmail && errors.orgEmail)}
                    errorMessage={String(touched.orgEmail && errors.orgEmail)}
                  />
                </div>
                <div className="mt-4">
                  <Input
                    id="userEmail"
                    label="User Email"
                    type="email"
                    {...getFieldProps('userEmail')}
                    icon={<MailIcon />}
                    error={Boolean(touched.userEmail && errors.userEmail)}
                    errorMessage={String(touched.userEmail && errors.userEmail)}
                  />
                </div>
                <div className="mt-4">
                  <Input
                    id="password"
                    label="User Password"
                    {...getFieldProps('password')}
                    icon={
                      showPassword ? (
                        <EyeIcon onClick={() => setShowPassword(false)} />
                      ) : (
                        <EyeOffIcon onClick={() => setShowPassword(true)} />
                      )
                    }
                    type={showPassword ? 'text' : 'password'}
                    error={Boolean(touched.password && errors.password)}
                    errorMessage={String(touched.password && errors.password)}
                  />
                </div>
                <div className="mt-10">
                  <Button
                    type="submit"
                    id={'sign-in-btn'}
                    size="lg"
                    disabled={isSubmitting}
                    className="bg-[#692cef] p-4 w-full rounded-full tracking-wide
                                font-semibold font-display focus:outline-none focus:shadow-outline
                                shadow-none hover:shadow-[#A6A4FE]"
                    title="Sign Up"
                  >
                    Sign Up
                  </Button>
                </div>
              </Form>
            </FormikProvider>
            <div className="mt-12 text-sm font-display font-semibold text-gray-600 text-center">
              By signing up, you agree with the{' '}
              <a className="cursor-pointer underline text-primary-500 hover:text-primary-700">
                Terms of Use
              </a>{' '}
              &{' '}
              <a className="cursor-pointer underline text-primary-500 hover:text-primary-700">
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:flex items-center justify-center bg-[#eab676] flex-1 h-screen">
        <div className="sm:max-w-xs lg:max-w-none cursor-pointer">
          <img src={RegistrationBanner} className="mx-auto" width={500} />
          <div className="text-gray-600 mt-10 text-center text-4xl font-bold">HealthTech</div>
          <br />
          <div className="text-center font-semibold text-gray-600">
            Health Revenue Cycle Management
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
