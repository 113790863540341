import React, { useState } from 'react';
import { Input as TWInput } from '@aurax/lumina-ui';

import { colors } from 'types';

interface InputProps {
  id: string;
  label: string;
  value?: any;
  color?: colors;
  error?: boolean;
  className?: string;
  disabled?: boolean;
  mandatory?: boolean;
  validation?: string;
  placeholder?: string;
  customProps?: object;
  autoComplete?: string;
  errorMessage?: string;
  inputSize?: 'md' | 'lg';
  icon?: React.ReactNode;
  variant?: 'static' | 'standard' | 'outlined';
  onChange: (selected: any, event?: any) => void;
  type?: 'email' | 'password' | 'text' | 'textarea' | 'color' | 'number';
}

const Input: React.FC<InputProps & React.InputHTMLAttributes<HTMLInputElement>> = ({
  id,
  icon,
  label,
  onChange,
  validation,
  error,
  value,
  errorMessage = '',
  autoComplete = 'off',
  placeholder,
  className = '',
  type = 'text',
  color = 'indigo',
  disabled = false,
  inputSize = 'md',
  mandatory = true,
  variant = 'outlined',
}) => {
  return (
    <div>
      <TWInput
        id={id}
        type={type}
        label={label}
        color={color}
        value={value}
        size={inputSize}
        autoComplete={autoComplete}
        variant={variant}
        disabled={disabled}
        className={className}
        required={mandatory}
        icon={icon ? icon : ''}
        itemProp=""
        placeholder={placeholder}
        onChange={onChange}
        error={error}
      />
      {errorMessage && error && <p className="text-sm text-red-500 mt-1">{errorMessage}</p>}
    </div>
  );
};

export default Input;
