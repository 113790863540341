import { Fragment, useState } from 'react';
import { ThemeProvider } from '@aurax/lumina-ui';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { Homepage } from 'views/Home';
import Example from 'views/Example/Index';
import { Dashboard } from 'views/Dashboard';
import Login from 'views/Authentication/Login';
import { Organization } from 'views/Organization';
import Register from 'views/Authentication/Register';
import ProtectedRoute, { ProtectedRouteProps } from './Protected';
import { ResetPassword } from 'views/Authentication/ResetPassword';
import { UpdatePassword } from 'views/Authentication/ResetPassword/UpdatePassword';

const Log = () => {
  const handleSubmit = (email: string, password: string) => {
    // Handle login logic here
  };

  return (
    <div className="flex flex-col md:flex-row bg-gradient-to-br from-purple-500 to-pink-500">
      <div
        className="bg-opacity-50 bg-cover bg-center md:h-screen md:w-1/2"
        style={{ backgroundImage: "url('/path/to/background-image.jpg')" }}
      ></div>
      <div className="bg-opacity-90 bg-white p-8 md:h-screen md:w-1/2 flex items-center justify-center">
        <div className="w-full max-w-md">
          <h1 className="text-3xl font-bold mb-8">Welcome Back!</h1>
          <Login />
        </div>
      </div>
    </div>
  );
};

const themeSetter = (ReactChild: any) => {
  return (
    <Fragment>
      <ThemeProvider>
        <ReactChild />
      </ThemeProvider>
    </Fragment>
  );
};

export const Routers = () => {
  // const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
  //   isAuthenticated: true,
  //   authenticationPath: '/login',
  // };
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  return(
  <BrowserRouter>
    {isLoggedIn ? (
      <>
        <Dashboard />
      </>
    ): (
      <Routes>
        <Route path='/' element={<Homepage />} />
        <Route path='/login' element={themeSetter(Login)} />
        <Route path='/register' element={themeSetter(Register)} />
        <Route path='/auth/reset-password' element={themeSetter(ResetPassword)} />
        <Route path='/auth/update-password/:uid/:token/' element={themeSetter(UpdatePassword)} />
        <Route path='/view' element={<Example />} />
      </Routes>
    )}
    </BrowserRouter>
  );
}
