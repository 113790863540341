/* eslint-disable jsx-a11y/anchor-is-valid */
import * as Yup from 'yup';
import React, { useState } from 'react';
import { Button } from '@aurax/lumina-ui';
import Logo from 'assets/icons/logo.svg';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';

import { useAppDispatch } from 'redux/store';
import Input from 'designSystem/Input/Index';
import LoginBanner from 'assets/svg/login_banner.svg';
import { LoginAccount } from 'redux/thunks/accountThunk';

const Login: React.FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useAppDispatch();
  let navigate = useNavigate();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
      ),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      // remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: (values: any) => {
      dispatch(LoginAccount(values));
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div className="lg:flex">
      <div className="lg:w-1/2">
        <div className="py-12  bg-[#eab676] lg:bg-white flex justify-center lg:justify-start lg:px-12">
          <div className="cursor-pointer flex items-center">
            <img src={Logo} alt="Logo" width={200} />
          </div>
        </div>
        <div className="mt-10 px-12 sm:px-24 md:px-48 lg:px-12 lg:mt-16 xl:px-24 xl:max-w-2xl">
          <h2
            className="text-center text-4xl text-black font-display font-semibold xl:text-5xl
                    xl:text-bold"
          >
            Sign In
          </h2>
          <div className="mt-12">
            <FormikProvider value={formik}>
              <Form autoComplete="nope" noValidate onSubmit={handleSubmit}>
                <Input
                  id="email"
                  label="Email"
                  type="email"
                  autoComplete="new-email"
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  errorMessage={String(touched.email && errors.email)}
                />
                <div className="mt-8">
                  <Input
                    id="password"
                    label="Password"
                    {...getFieldProps('password')}
                    icon={
                      showPassword ? (
                        <EyeIcon
                          className="h-5 w-5 text-gray-500"
                          onClick={() => setShowPassword(false)}
                        />
                      ) : (
                        <EyeOffIcon
                          className="h-5 w-5 text-gray-500"
                          onClick={() => setShowPassword(true)}
                        />
                      )
                    }
                    autoComplete="new-password"
                    type={showPassword ? 'text' : 'password'}
                    error={Boolean(touched.password && errors.password)}
                    errorMessage={String(touched.password && errors.password)}
                  />
                </div>
                <div>
                  <a
                    className="text-xs font-display mt-2 font-semibold text-[#692cef] hover:text-[#0502AB]
                                        cursor-pointer float-right"
                    onClick={() => navigate("/auth/reset-password")}
                  >
                    Forgot Password?
                  </a>
                </div>
                <div className="mt-10">
                  <Button
                    type="submit"
                    id={'sign-in-btn'}
                    ripple={true}
                    size="lg"
                    disabled={isSubmitting}
                    className="bg-[#692cef] p-4 w-full rounded-full tracking-wide
                                font-semibold font-display focus:outline-none focus:shadow-outline
                                shadow-none hover:shadow-[#A6A4FE]"
                    title="Sign In"
                  >
                    Sign In
                  </Button>
                </div>
              </Form>
            </FormikProvider>
            <div className="mt-12 text-sm font-display font-semibold text-gray-600 text-center">
              Don't have an account ?{' '}
              <a className="cursor-pointer text-[#692cef] hover:text-[#0502AB]" onClick={() => navigate("/register")}>Sign up</a>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:flex items-center justify-center bg-[#eab676] flex-1 h-screen">
        <div className="sm:max-w-xs lg:max-w-none cursor-pointer">
          <img src={LoginBanner} className="mx-auto" width={460} />
          <div className="text-gray-600 mt-10 text-center text-4xl font-bold">HealthTech</div>
          <br />
          <div className="text-center font-semibold text-gray-600">
            Health Revenue Cycle Management
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
