import { Body } from './fragments/Body';
import { Navbar } from './fragments/Navbar';

import { useState } from 'react';
import { Sidebar } from '../../designSystem/Sidebar';
import { SidebarData } from './fragments/sidebarData';
import { Navigate, Route, Routes } from 'react-router';
import { Registration } from '../Registration';
import { Appointments } from '../Appointments';
import { AppointmentSchedule } from '../Appointments/Schedule';

export const Dashboard: React.FC = () => {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} menuItems={SidebarData} />
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* <Body /> */}
            {/* <Redirect exact={true} from="/" to="/admin/test-list" /> */}
            <Routes>
              <Route path="/" element={<Navigate replace to="/registration/register" />} />
                <Route path="/registration/register">
                  <Route index element={<Registration />} />
                </Route>
                <Route path="/appointments">
                  <Route path="list" element={<Appointments />} />
                  <Route path="schedule" element={<AppointmentSchedule />} />
                </Route>
                <Route path="/archives">
                  <Route index element={<Appointments />} />
                </Route>
            </Routes>
          </div>
        </main>
      </div>
    </div>
  );
};
