import React, { ButtonHTMLAttributes } from 'react';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  svg?: React.ReactNode;
  gradient?: string;
}

const Button: React.FC<ButtonProps> = ({ label, svg, gradient, className, disabled, ...rest }) => {
  const bgGradient = gradient ;

  const buttonClasses = `flex items-center justify-center py-2 px-4 bg-gradient-to-br ${bgGradient} text-white font-medium rounded-lg shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${className}`;

  return (
    <button className={buttonClasses} disabled={disabled} {...rest}>
      {svg && <span className="mr-2">{svg}</span>}
      {label}
    </button>
  );
};

export default Button;
