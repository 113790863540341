import * as Yup from 'yup';
import { FC, useState } from 'react';
import { KeyIcon, MailIcon } from '@heroicons/react/outline';
import { useFormik, Form, FormikProvider } from 'formik';

import { Button } from '@aurax/lumina-ui';
import Input from 'designSystem/Input/Index';
import Background from 'assets/svg/blob-bg.svg';

export const UpdatePassword: FC = () => {
  const updatePasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
      ),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
      ),
  });
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: updatePasswordSchema,
    onSubmit: (values: any, { resetForm, setSubmitting }) => {
      const { password, confirmPassword } = values;
      resetForm({
        values: {
          password: '',
          confirmPassword: '',
        },
      });
      setSubmitting(false);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <section className="p-4 w-full h-screen">
      <div className="rounded-xl overflow-hidden w-full h-1/2 relative">
        <img
          src={Background}
          alt="background image"
          className="object-cover w-full h-full m-w-full"
        />
        <div className="absolute h-full w-full mt-0 ml-0"></div>
      </div>
      <div className="justify-center grid ml-auto mr-auto -mt-8">
        <div className="md:w-98 shadow drop-shadow-md bg-clip-border bg-white rounded-xl flex flex-col relative ml-4 mr-4">
          <div className="bg-[#692cef] shadow-lg shadow-[#A6A4FE] text-center py-8 px-4 bg-clip-border grid mb-4 -mt-6 mx-4 relative rounded-xl">
            <h3 className="antialiased text-white font-semibold block mb-2 m-0">Update Password</h3>
            <p className="antialiased text-white font-light block m-0">
              Please enter your new password to continue
            </p>
          </div>
          <div className="p-6 gap-4 flex flex-col">
            <FormikProvider value={formik}>
              <Form autoComplete="nope" noValidate onSubmit={handleSubmit}>
                <div className="min-w-[200px] w-full h-24 mt-2 relative ">
                  <Input
                    id="password"
                    label="Password"
                    type="password"
                    icon={<KeyIcon />}
                    autoComplete="new-password"
                    {...getFieldProps('password')}
                    error={Boolean(touched.password && errors.password)}
                    errorMessage={String(touched.password && errors.password)}
                  />
                  <div className={errors.confirmPassword ? 'my-1' : 'my-4'}>
                    <Input
                      type="password"
                      icon={<KeyIcon />}
                      id="confirmPassword"
                      label="Confirm Password"
                      autoComplete="new-password"
                      {...getFieldProps('confirmPassword')}
                      error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                      errorMessage={String(touched.confirmPassword && errors.confirmPassword)}
                    />
                  </div>
                </div>
                <Button
                  size="lg"
                  type="submit"
                  id={'update-btn'}
                  ripple={true}
                  disabled={isSubmitting}
                  className="bg-[#692cef] p-4 mt-10 w-full rounded-full tracking-wide
                                font-semibold font-display focus:outline-none focus:shadow-outline
                                shadow-none hover:shadow-[#A6A4FE]"
                  title="Update"
                >
                  Update
                </Button>
              </Form>
            </FormikProvider>
          </div>
        </div>
      </div>
    </section>
  );
};
