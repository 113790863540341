import { combineReducers } from '@reduxjs/toolkit';
import toastSlice from 'redux/slices/toast';
import { createAccountSlice } from '../slices/account';

const rootReducer = combineReducers({
  account: createAccountSlice.reducer,
  toast: toastSlice.reducer
});

export default rootReducer;
