import * as Yup from 'yup';
import { FC, useState } from 'react';
import { MailIcon } from '@heroicons/react/outline';
import { useFormik, Form, FormikProvider } from 'formik';

import { Button } from '@aurax/lumina-ui';
import Input from 'designSystem/Input/Index';
import Background from 'assets/svg/blob-bg.svg';
import { useAppDispatch } from 'redux/store';
import { ResetAccountPassword } from 'redux/thunks/accountThunk';

export const ResetPassword: FC = () => {
  const dispatch = useAppDispatch();
  const resetPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  });
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: resetPasswordSchema,
    onSubmit: (values: any, { resetForm, setSubmitting }) => {
      dispatch(ResetAccountPassword(values));
      resetForm({
        values: {
          email: '',
        },
      });
      setSubmitting(false);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <section className="p-4 w-full h-screen">
      <div className="rounded-xl overflow-hidden w-full h-1/2 relative">
        <img
          src={Background}
          alt="background image"
          className="object-cover w-full h-full m-w-full"
        />
        <div className="absolute h-full w-full mt-0 ml-0"></div>
      </div>
      <div className="justify-center grid ml-auto mr-auto -mt-8">
        <div className="md:w-98 shadow drop-shadow-md bg-clip-border bg-white rounded-xl flex flex-col relative ml-4 mr-4">
          <div className="bg-[#692cef] shadow-lg shadow-[#A6A4FE] text-center py-8 px-4 bg-clip-border grid mb-4 -mt-6 mx-4 relative rounded-xl">
            <h3 className="antialiased text-white font-semibold block mb-2 m-0">Reset Password</h3>
            <p className="antialiased text-white font-light block m-0">
              You will receive an e-mail in maximum 60 seconds
            </p>
          </div>
          <div className="p-6 gap-4 flex flex-col">
            <FormikProvider value={formik}>
              <Form autoComplete="nope" noValidate onSubmit={handleSubmit}>
                <div className="min-w-[200px] w-full h-12 mt-2 relative ">
                  <Input
                    id="email"
                    label="Email"
                    type="email"
                    icon={<MailIcon />}
                    autoComplete="new-email"
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    errorMessage={String(touched.email && errors.email)}
                  />
                </div>
                <Button
                  size="lg"
                  type="submit"
                  id={'reset-btn'}
                  ripple={true}
                  disabled={isSubmitting}
                  className="bg-[#692cef] p-4 my-8 w-full rounded-full tracking-wide
                                font-semibold font-display focus:outline-none focus:shadow-outline
                                shadow-none hover:shadow-[#A6A4FE]"
                  title="Reset"
                >
                  Reset
                </Button>
              </Form>
            </FormikProvider>
          </div>
        </div>
      </div>
    </section>
  );
};
