import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast, ToastOptions } from 'react-toastify';

export interface ToastState {
  message: string;
  options?: ToastOptions;
}

const initialState: ToastState = {
  message: '',
};

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    showToast(state, action: PayloadAction<ToastState>) {
      state.message = action.payload.message;
      state.options = action.payload.options;
      toast.success(state.message);
    },
  },
});

export const { showToast } = toastSlice.actions;
export const selectToast = (state) => state.toast;

export default toastSlice
