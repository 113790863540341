export const SidebarData = [
  {
    label: 'Registration',
    to: '/registration/register',
    id: 'registration',
    logo: (
      <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
        <path
          color="#4c2cb1"
          className="fill-current"
          d="M18.974 8H22a2 2 0 012 2v6h-2v5a1 1 0 01-1 1h-2a1 1 0 01-1-1v-5h-2v-6a2 2 0 012-2h.974zM20 7a2 2 0 11-.001-3.999A2 2 0 0120 7zM2.974 8H6a2 2 0 012 2v6H6v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5H0v-6a2 2 0 012-2h.974zM4 7a2 2 0 11-.001-3.999A2 2 0 014 7z"
        />
        <path
          color="#692cef"
          className="fill-current"
          d="M12 6a3 3 0 110-6 3 3 0 010 6zm2 18h-4a1 1 0 01-1-1v-6H6v-6a3 3 0 013-3h6a3 3 0 013 3v6h-3v6a1 1 0 01-1 1z"
        />
      </svg>
    ),
    isDefault: true,
    defaultSubMenu: 'register',
    subMenu: [
      {
        label: 'Register',
        to: '/registration/register',
        id: 'register',
      },
    ],
  },
  {
    label: 'Appointments',
    to: '/appointments/list',
    id: 'appointments',
    logo: (
      <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
        <path color="#44546c" className="fill-current" d="M1 3h22v20H1z" />
        <path color="#94a4bc" className="fill-current" d="M21 3h2v4H1V3h2V1h4v2h10V1h4v2Z" />
      </svg>
    ),
    defaultSubMenu: 'appointment-list',
    subMenu: [
      {
        label: 'Appointment List',
        to: '/appointments/list',
        id: 'appointment-list',
      },
      {
        label: 'Schedule',
        to: '/appointments/schedule',
        id: 'appointment-schedule',
      },
    ],
  },
  {
    label: 'Archive Data',
    to: '/archives',
    logo: (
      <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
        <path color="#94a4bc" className="fill-current" d="M16 13v4H8v-4H0l3-9h18l3 9h-8Z" />
        <path
          color="#44546c"
          className="fill-current"
          d="m23.72 12 .229.686A.984.984 0 0 1 24 13v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-8c0-.107.017-.213.051-.314L.28 12H8v4h8v-4H23.72ZM13 0v7h3l-4 5-4-5h3V0h2Z"
        />
      </svg>
    ),
    defaultSubMenu: 'archives',
    subMenu: [
      {
        label: 'Archives',
        to: '/archives',
        id: 'archives',
      },
    ],
  },
];
