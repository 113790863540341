import { createAsyncThunk } from '@reduxjs/toolkit';
import { IOrganizationSchema, IUserSchema, TAccount } from 'types/account';
import {
  createAccountFailure,
  createAccountStart,
  createAccountSuccess,
  loginStart,
  loginFailure,
  loginSuccess,
  resetPasswordStart,
  resetPasswordSuccess,
  resetPasswordFailure,
} from 'redux/slices/account';
import AxiosClient from 'services/axios';
import { showToast } from 'redux/slices/toast';

export const createAccount: any = createAsyncThunk(
  'account/create',
  async (
    { userPayload, orgPayload }: { userPayload: IUserSchema; orgPayload: IOrganizationSchema },
    { dispatch },
  ) => {
    try {
      dispatch(createAccountStart());
      const axiosUtility = new AxiosClient();

      const response: any = await axiosUtility.post<TAccount>('organization/create/', {
        user_payload: userPayload,
        org_payload: orgPayload,
      });

      dispatch(createAccountSuccess(response.data));
      dispatch(showToast({message: "Organization registered successfully", options: {}}))
      return response.data;
    } catch (error) {
      dispatch(createAccountFailure(error.message));
      dispatch(showToast({message: error.message, options: {}}))
      throw error;
    }
  },
);

export const LoginAccount: any = createAsyncThunk(
  'account/login',
  async (userPayload: IUserSchema, { dispatch }) => {
    try {
      dispatch(loginStart());
      const axiosUtility = new AxiosClient();

      const response: any = await axiosUtility.post<IUserSchema>(
        'auth/login/',
        userPayload,
      );

      dispatch(loginSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(loginFailure(error.message));
      throw error;
    }
  },
);

export const ResetAccountPassword: any = createAsyncThunk(
  'reset/password',
  async (email: String, { dispatch }) => {
    try {
      dispatch(resetPasswordStart());
      const axiosUtility = new AxiosClient();

      const response: any = await axiosUtility.post<{email: String}>(
        'auth/user/reset-password/',
        email,
      );

      dispatch(resetPasswordSuccess(response.data));
      dispatch(showToast({message: "Reset mail sent successfully", options: {}}))
      return response.data;
    } catch (error) {
      dispatch(resetPasswordFailure(error.message));
      dispatch(showToast({message: error.message, options: {}}))
      throw error;
    }
  },
);
